import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { COMMON_BASE_URL } from '../../../core/tokens/common-base-url.token';
import { IApi } from '../../../core/interrfaces/IApi';
import { Observable } from 'rxjs';
import { ICertificatePrice } from '../interfaces/ICertificatePrice';
import { ICertificate } from '../interfaces/ICertificate';
import { BaseApi } from '../../../core/base/BaseApi';
import { ICommonResultResponse } from '../../../core/interrfaces/ICommonResultResponse';
import { ICertificateInfo } from '../interfaces/ICertificateInfo';
import { ICertificateRequestInfo } from '../interfaces/ICertificateRequestInfo';

@Injectable()
export class CertificateApi extends BaseApi implements IApi {
  constructor(
    private http: HttpClient,
    @Inject(COMMON_BASE_URL) private baseUrl: string
  ) {
    super();
  }

  getPrices(): Observable<ICertificatePrice[]> {
    return this.http.get<ICertificatePrice[]>(this.buildBaseUrl('api/gift_certificates/available'));
  }

  buildBaseUrl(input: string): string {
    return this.baseUrl + '/' + input;
  }

  getByCode(code: string): Observable<ICertificate> {
    return this.http.get<ICertificate>(this.buildBaseUrl('api/gift_certificates/info'), {
      params: this.params({ code }),
    });
  }

  activate(certificate: string): Observable<ICommonResultResponse> {
    return this.http.put<ICommonResultResponse>(
      this.buildBaseUrl('api/gift_certificates'),
      this.params({ certificate })
    );
  }

  create(stripe_token: string, data: ICertificateRequestInfo): Observable<ICertificateInfo> {
    return this.http.post<ICertificateInfo>(this.buildBaseUrl('api/gift_certificates'), {
      ...{ stripe_token },
      ...data,
    });
  }
}
