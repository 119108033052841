import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ILoading } from '../../../core/interrfaces/ILoading';

@Injectable()
export class GiftCertificatesService implements ILoading {
  private _loading$ = new BehaviorSubject<boolean>(false);

  stopLoading(): void {
    this._loading$.next(false);
  }

  startLoading(): void {
    this._loading$.next(true);
  }

  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }
}
