import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICertificate } from './interfaces/ICertificate';
import { CertificateApi } from './api/certificate.api';
import { ICertificatePrice } from './interfaces/ICertificatePrice';
import { tap } from 'rxjs/operators';
import { CertificateState } from './state/certificate.state';
import { ICertificateRequestInfo } from './interfaces/ICertificateRequestInfo';
import { ICertificateInfo } from './interfaces/ICertificateInfo';

@Injectable()
export class CertificateFacade {
  constructor(
    private certificateState: CertificateState,
    private certificateApi: CertificateApi
  ) {}

  get certificatePrices(): ICertificatePrice[] | null {
    return this.certificateState.getCertificatePrices();
  }

  get certificatePrices$(): Observable<ICertificatePrice[] | null> {
    return this.certificateState.getCertificatePrices$();
  }

  loadCertificateByCode(code: string): Observable<ICertificate> {
    return this.certificateApi.getByCode(code);
  }

  loadCertificatePrices(): Observable<ICertificatePrice[]> {
    return this.certificateApi
      .getPrices()
      .pipe(tap(prices => this.certificateState.setCertificatePrices(prices)));
  }

  create(stripe: string, data: ICertificateRequestInfo): Observable<ICertificateInfo> {
    return this.certificateApi.create(stripe, data);
  }
}
