import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICertificatePrice } from '../interfaces/ICertificatePrice';

@Injectable()
export class CertificateState {
  private certificatePrices$ = new BehaviorSubject<ICertificatePrice[] | null>(null);

  getCertificatePrices$(): Observable<ICertificatePrice[] | null> {
    return this.certificatePrices$.asObservable();
  }

  getCertificatePrices(): ICertificatePrice[] | null {
    return this.certificatePrices$.getValue();
  }

  setCertificatePrices(prices: ICertificatePrice[]): void {
    this.certificatePrices$.next(prices);
  }
}
